import './bootstrap';
import {Alpine} from './alpine/index';
// import 'livewire-sortable'
import './correct-slider'
import './animation/home_page'
import './animation/product_page'
import './animation/category_page'
import './accordion'

import.meta.glob([
	'../images/**',
]);
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
function setWindowHeight(){
	var windowHeight = window.innerHeight;
	document.body.style.height = windowHeight + "px";
	// console.log(document.body.style.height);
	let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
	document.documentElement.style.setProperty('--vh', `${vh}px`);
}
window.addEventListener("resize",setWindowHeight,false);

Alpine.start();


window.copy_to_clipboard = (e,data) => copy_to_clipboard(e,data);

async function copy_to_clipboard(e,textToCopy) {
	let parent = e.target.closest('.copy_btn');
	parent.classList.add('copied');
	setTimeout(function () {
		parent.classList.remove('copied');
	}, 1000);


	console.log(textToCopy);
	console.log(navigator.clipboard);
	if (navigator.clipboard && window.isSecureContext) {
		await navigator.clipboard.writeText(textToCopy);
	} else {
		// Use the 'out of viewport hidden text area' trick
		const textArea = document.createElement("textarea");
		textArea.value = textToCopy;

		// Move textarea out of the viewport so it's not visible
		textArea.style.position = "absolute";
		textArea.style.left = "-999999px";

		document.body.prepend(textArea);
		textArea.select();

		try {
			document.execCommand('copy');
		} catch (error) {
			console.error(error);
		} finally {
			textArea.remove();
		}
	}
}
