import Swiper from "swiper";
import { Thumbs, Navigation } from "swiper/modules";

// Post page (single)

const swiper = new Swiper(".gallery-thumbs", {
	loop: true,
	spaceBetween: 16,
	slidesPerView: 3,
    breakpoints: {
        600: {
            slidesPerView: 4.5
        },
        768: {
            slidesPerView: 6.5
        },
        1023: {
            slidesPerView: 6.8
        },
        1280: {
            slidesPerView: 7.8
        },
        1600: {
            slidesPerView: 10.7
        }
    }
});
const swiper2 = new Swiper(".gallery-top", {
	modules: [Thumbs, Navigation],
	loop: true,
	spaceBetween: 10,
	slidesPerView: 1,
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},
	thumbs: {
		swiper: swiper,
	},
});

// Submenu

const submenuPosts = new Swiper(".submenu-posts-slider", {
    modules: [Navigation],
    navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},
    slidesPerView: 2,
    breakpoints: {
        768: {
            slidesPerView: 4
        }
    }
})

const submenuReports = new Swiper(".submenu-reports-slider", {
    modules: [Navigation],
    navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},
    slidesPerView: 3,
    breakpoints: {
        600: {
            slidesPerView: 4
        },
        768: {
            slidesPerView: 8
        },
        1279: {
            slidesPerView: 9
        },
        1600: {
            slidesPerView: 13
        }
    }
})

// About us page


// Docs (modal)

const sliderDocsThumb = new Swiper(".slider-docs-thumbs", {
	loop: true,
	spaceBetween: 16,
	slidesPerView: 3,
    breakpoints: {
        600: {
            slidesPerView: 4.5
        },
        768: {
            slidesPerView: 6.5
        },
        1023: {
            slidesPerView: 6.8
        },
        1280: {
            slidesPerView: 7.8
        },
        1600: {
            slidesPerView: 10.7
        }
    }
});
const sliderDocsTop = new Swiper(".slider-docs-top", {
	modules: [Thumbs, Navigation],
	loop: true,
	spaceBetween: 10,
	slidesPerView: 1,
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},
	thumbs: {
		swiper: sliderDocsThumb,
	},
});

// Docs (mob)

const sliderDocsMobThumb = new Swiper(".slider-docs-mob-thumbs", {
	loop: true,
	spaceBetween: 16,
	slidesPerView: 3,
});
const sliderDocsMobTop = new Swiper(".slider-docs-mob-top", {
	modules: [Thumbs, Navigation],
	loop: true,
	spaceBetween: 10,
	slidesPerView: 1,
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},
	thumbs: {
		swiper: sliderDocsMobThumb,
	},
});

// Statute (modal)

const sliderStatThumb = new Swiper(".slider-stat-thumbs", {
	loop: true,
	spaceBetween: 16,
	slidesPerView: 3,
    breakpoints: {
        600: {
            slidesPerView: 4.5
        },
        768: {
            slidesPerView: 6.5
        },
        1023: {
            slidesPerView: 6.8
        },
        1280: {
            slidesPerView: 7.8
        },
        1600: {
            slidesPerView: 10.7
        }
    }
});
const sliderStatTop = new Swiper(".slider-stat-top", {
	modules: [Thumbs, Navigation],
	loop: true,
	spaceBetween: 10,
	slidesPerView: 1,
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},
	thumbs: {
		swiper: sliderStatThumb,
	},
});

// Statue (mob)

const sliderStatMobThumb = new Swiper(".slider-stat-mob-thumbs", {
	loop: true,
	spaceBetween: 16,
	slidesPerView: 3,
});
const sliderStatMobTop = new Swiper(".slider-stat-mob-top", {
	modules: [Thumbs, Navigation],
	loop: true,
	spaceBetween: 10,
	slidesPerView: 1,
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},
	thumbs: {
		swiper: sliderStatMobThumb,
	},
});