let acc = document.getElementsByClassName("question_title");
let i;

for (i = 0; i < acc.length; i++) {
  acc[i].addEventListener("click", function() {
    /* Toggle between adding and removing the "active" class,
    to highlight the button that controls the panel */

    this.classList.toggle("active");
    // console.log(this.lastElementChild)
    // this.lastElementChild.classList.toggle('rotate')

    // this.lastElementChild.style.transform = 'rotate(-180deg)';

    /* Toggle between hiding and showing the active panel */
    let icon = this.lastElementChild;
    let answer = this.nextElementSibling;

    icon.style.transition = '0.2s';

    if (icon.style.transform) {
        icon.style.transform = null
    } else {
        icon.style.transform = 'rotate(-180deg)'
    }

    if (answer.style.maxHeight) {
        answer.style.maxHeight = null;
      } else {
        answer.style.maxHeight = answer.scrollHeight + "px";
      }

  });
}
console.log('asdasdsa')